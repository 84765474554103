<template>
  <div>
    <div class="nav clearfix matchBus">
      <h1><span @click="$router.back()">返回</span>匹配工具</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/match/index' }"
          >智能匹配</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策结果</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <h1>产品条件信息</h1>
      <el-form ref="form" :label-position="labelPosition" label-width="80px">
        <el-form-item label="产品名称">
          <el-input placeholder="请输入" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="客户来源类型">
          <el-select placeholder="请选择类型" style="width: 370px">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="申报条件" style="width: 100%">
          <div class="shenbao">
            <el-checkbox-group v-model="checkList" style="width: 100%">
              <el-checkbox label="条件1" name="type">条件1</el-checkbox>
              <el-checkbox label="条件2" name="type">条件2</el-checkbox>
              <el-checkbox label="条件3" name="type">条件3</el-checkbox>
              <el-checkbox label="条件4" name="type">条件4</el-checkbox>
              <el-checkbox label="条件5" name="type">条件5</el-checkbox>
              <el-checkbox label="条件6" name="type">条件6</el-checkbox>
              <el-checkbox label="条件7" name="type">条件7</el-checkbox>
              <el-checkbox label="条件8" name="type">条件8</el-checkbox>
              <el-checkbox label="条件9" name="type">条件9</el-checkbox>
              <el-checkbox label="条件10" name="type">条件10</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <div class="busFlex">
          <div class="busFlexBox" style="width: 25%">
            <el-form-item label="其他条件1">
              <el-select placeholder="请选择" style="width: 90%">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="busFlexBox" style="width: 25%">
            <el-form-item label="其他条件2">
              <el-select placeholder="请选择" style="width: 90%">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="busFlexBox" style="width: 25%">
            <el-form-item label="其他条件3">
              <el-select placeholder="请选择" style="width: 90%">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="busFlexBox" style="width: 25%">
            <el-form-item label="其他条件4">
              <el-select placeholder="请选择" style="width: 90%">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <el-form-item style="width: 100%; margin-top: 100px">
          <div class="foot_TJ">
            <el-button type="primary" @click="onSubmit">开始匹配</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "top",
      checkList: [],
      form: {
        name: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("开始匹配");
    },
  },
};
</script>

<style scoped>
.matchBus .el-form-item__label {
  width: 100% !important;
  text-align: left;
}

.matchBus .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.matchBus .el-select {
  width: 100%;
}

.matchBus .el-checkbox {
  float: left;
  color: #909399;
}
.matchBus .el-radio-group {
  float: right;
}
.matchBus h5 {
  font-size: 16px;
  color: #909399;
  font-weight: 400;
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.busFlex {
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.shenbao {
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.shenbao .el-checkbox {
  width: 8%;
  color: #909399;
}
.el-form--label-top >>> .el-form-item__label {
  color: #404040;
}
</style>
